var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "training-card" },
    [
      _vm.can_delete
        ? _c("RemoveConfirmModal", {
            attrs: {
              id: String(_vm.training.id),
              itemName: _vm.training.slug || _vm.training.id,
              confirmationValue: _vm.training.slug || _vm.training.id,
              show: _vm.showRemoveConfirmModal,
            },
            on: {
              close: function ($event) {
                _vm.showRemoveConfirmModal = false
              },
              success: function ($event) {
                return _vm.$emit("remove", $event)
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "training-card__wrapper" },
        [
          _vm.can_delete && !_vm.showBlackFriday
            ? _c(
                "div",
                { staticClass: "training-card__controls" },
                [
                  _vm.can_delete
                    ? _c(
                        "CButton",
                        {
                          attrs: { color: "danger" },
                          on: {
                            click: function ($event) {
                              _vm.showRemoveConfirmModal = true
                            },
                          },
                        },
                        [_c("CIcon", { attrs: { name: "cil-x" } })],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "router-link",
            {
              staticClass: "training-card__link",
              attrs: {
                to: {
                  name: _vm.to,
                  params: {
                    id: _vm.training.id,
                    slug: _vm.training.slug || _vm.training.id,
                  },
                },
              },
            },
            [
              _vm.showMarkers && _vm.training.tariffOptions.length
                ? _c(
                    "div",
                    { staticClass: "training-card__markers" },
                    _vm._l(_vm.training.tariffOptions, function (tariff) {
                      return _c(
                        "div",
                        {
                          key: tariff.name.en,
                          staticClass: "training-card__marker-item",
                          style: {
                            backgroundColor:
                              tariff.color || _vm.markerDefaultColor,
                          },
                        },
                        [
                          tariff.name
                            ? _c(
                                "span",
                                { staticClass: "training-card__marker-type" },
                                [_vm._v(_vm._s(tariff.name[_vm.locale]))]
                              )
                            : _vm._e(),
                          tariff.date
                            ? _c(
                                "span",
                                { staticClass: "training-card__marker-date" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("lk.training.courseMarkerLabel")
                                    ) +
                                      " " +
                                      _vm._s(_vm.formatDate(tariff.date))
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.training.course_cover_photo
                ? _c("img", {
                    attrs: {
                      src: `${_vm.baseUrl}${_vm.training.course_cover_photo}`,
                      alt: _vm.training.course_title,
                    },
                  })
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            { staticClass: "training-card__content" },
            [
              _c("div", { staticClass: "training-card__title-wrapper" }, [
                _vm.training.course_title
                  ? _c("h4", { staticClass: "training-card__title" }, [
                      _vm._v(" " + _vm._s(_vm.training.course_title) + " "),
                    ])
                  : _vm._e(),
              ]),
              _vm.training?.authors?.length
                ? [
                    _c("small-author", {
                      staticClass: "training-card__author",
                      attrs: { author: _vm.training.authors[0] },
                    }),
                  ]
                : _vm._e(),
              _vm.isSubInfo && _vm.showCurator
                ? [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show-slide",
                            rawName: "v-show-slide:600",
                            value: _vm.showSubInfo,
                            expression: "showSubInfo",
                            arg: "600",
                          },
                        ],
                        staticClass: "training-card__sub-info",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "training-card__sub-info-content" },
                          [
                            _vm.curator && !_vm.isGuest
                              ? [
                                  _c("small-author", {
                                    staticClass: "training-card__author",
                                    attrs: {
                                      author: _vm.curator,
                                      isCurator: true,
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _vm.showLinkChat
                              ? _c(
                                  "CButton",
                                  {
                                    staticClass: "training-card__chat",
                                    attrs: {
                                      role: "button",
                                      color: "primary",
                                      target: "_blank",
                                      variant: _vm.training.userIsEnterTgChannel
                                        ? "outline"
                                        : "",
                                    },
                                    on: { click: _vm.goGroupChat },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.training.userIsEnterTgChannel
                                              ? "Вы вступили в чат"
                                              : "Вступить в чат"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("CIcon", {
                                      attrs: { name: "cibTelegramPlane" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }