<template>
  <CModal
    centered
    :closeOnBackdrop="true"
    :title="$t('general.remove_data', {item: itemName}).toString()"
    :show="show"
    @update:show="close"
  >
    <CInput
      v-if="confirmationValue"
      v-model="confirmValue"
      :label="confirmText + confirmationValue"
      :placeholder="confirmText + confirmationValue"
      type="text"
    />

    <div class="RemoveConfirmModal__btnBlock">
      <CLoadingButtonCustom
        class="shadow-none RemoveConfirmModal__btn"
        color="success"
        :loading="loading"
        :disabled="loading || disabledForm"
        @click.native="$emit('success', id)"
      >
        {{$t('general.delete')}}
      </CLoadingButtonCustom>
      <CButton class="shadow-none RemoveConfirmModal__btn"
               color="primary"
               :disabled="loading"
               @click="close">
        {{$t('general.cancel')}}
      </CButton>
    </div>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
export default {
  name: 'RemoveConfirmModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    itemName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: function (){
        return this.$t('general.remove_data', {item: this.itemName})
      }
    },
    id: {
      type: String,
      required: true
    },
    confirmationValue: {
      type: String,
      default: null
    },
    confirmText: {
      type: String,
      default: function (){
        return this.$t('general.writeTheText')
      }
    }
  },
  data() {
    return {
      confirmValue: ''
    }
  },
  watch: {
    'show': function (val) {
      if (val) {
        this.confirmValue = ''
      }
    }
  },
  computed: {
    disabledForm() {
      return this.confirmationValue && this.confirmValue !== this.confirmationValue
    }
  },
  methods: {
    close() {
      this.confirmValue = ''
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.RemoveConfirmModal{

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-top: 16px;
    font-size: 21px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 0 32px;
      padding-top: 24px;
    }

    &-close {
      font-size: 46px;
      font-weight: 100;
      line-height: 10px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 44px;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
</style>

</style>
