var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-training", class: { "_is-carousel": _vm.isCarousel } },
    [
      _vm.loading
        ? _c("CSpinner", { attrs: { color: "primary", size: "lg" } })
        : _vm._e(),
      _vm.isCarousel
        ? _c("div", { ref: "swiper", staticClass: "swiper" }, [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.items, function (training) {
                return _c("training-card", {
                  key: training.id,
                  staticClass: "swiper-slide list-training__item",
                  attrs: {
                    to: _vm.to,
                    can_delete: _vm.can_delete,
                    training: training,
                    showMarkers: _vm.showMarkers,
                    showCurator: _vm.showCurator,
                  },
                  on: { remove: _vm.removeItem },
                })
              }),
              1
            ),
          ])
        : _c(
            "div",
            { staticClass: "list-training__wrapper" },
            _vm._l(_vm.items, function (training) {
              return _c("training-card", {
                key: training.id,
                staticClass: "list-training__item",
                attrs: {
                  to: _vm.to,
                  can_delete: _vm.can_delete,
                  training: training,
                  showMarkers: _vm.showMarkers,
                  showCurator: _vm.showCurator,
                },
                on: { remove: _vm.removeItem },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }