<template>
  <div
    class="list-training"
    :class="{ '_is-carousel': isCarousel }"
  >
    <CSpinner
      v-if="loading"
      color="primary"
      size="lg"
    />
    <div
      class="swiper"
      v-if="isCarousel"
      ref="swiper"
    >
      <div class="swiper-wrapper">
        <training-card
          v-for="training in items"
          :key="training.id"
          :to="to"
          :can_delete="can_delete"
          @remove="removeItem"
          :training="training"
          :showMarkers="showMarkers"
          :showCurator="showCurator"
          class="swiper-slide list-training__item"
        ></training-card>
      </div>
    </div>
    <div
      class="list-training__wrapper"
      v-else
    >
      <training-card
        v-for="training in items"
        :key="training.id"
        :to="to"
        :can_delete="can_delete"
        @remove="removeItem"
        :training="training"
        :showMarkers="showMarkers"
        :showCurator="showCurator"
        class="list-training__item"
      ></training-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import TrainingCard from '@/components/training/TrainingCard'
import Swiper from 'swiper'
import Author from '@/assets/js/Author.class'

export default {
  name: 'ListTraining',
  props: {
    to: {
      type: String,
      default: '',
    },
    toAuthor: {
      type: String,
      default: '',
    },
    courses: {
      type: Array,
    },
    onlyControl: {
      type: Boolean,
      default: false,
    },
    onlyAccess: {
      type: Boolean,
      default: false,
    },
    showMarkers: {
      type: Boolean,
      default: false,
    },
    isCarousel: {
      type: Boolean,
      default: false,
    },
    showCurator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseUrl: axios.defaults.baseURL,
      loading: false,
      items: [],
      tariffs: {
        standard_edu: {
          color: '#FFFFFF',
          name: { ru: 'Standard', en: 'Standard' },
          date: null,
        },
        all_in_edu: {
          color: '#FCB81C',
          name: { ru: 'ALL-IN', en: 'ALL-IN' },
          date: null,
        },
        mentorship_edu: {
          color: '#4DBD74',
          name: { ru: 'Mentorship', en: 'Mentorship' },
          date: null,
        },
        web3_edu: {
          color: '#f66936',
          name: { ru: 'WEB3', en: 'WEB3' },
          date: null,
        },
        crypto_basics_edu: {
          color: '#f66936',
          name: { ru: 'Crypto Basics', en: 'Crypto Basics' },
          date: null,
        },
      },
      can_delete: false,
    }
  },
  created() {
    this.getListTraining()
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.swiper) {
        this.swiper = new Swiper(this.$refs.swiper, {
          slidesPerView: 'auto',
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          on: {
            init(swiper) {
              setTimeout(() => {
                try {
                  swiper.update()
                } catch (e) {}
              }, 300)
            },
          },
        })
      }
    })
  },
  methods: {
    getListTraining() {
      if (this.courses && this.courses?.length) {
        this.items = this.courses
        return
      }

      this.loading = true

      axios
        .get(`${this.baseUrl}/api/v2/courses`, {
          params: {
            with: ['authors'],
          },
        })
        .then((res) => {
          const data = res?.data?.data
          this.can_delete = res?.data?.can_delete || false
          this.items = []
          for (let key in data) {
            data[key]['tariffOptions'] = []

            if (this.onlyAccess && (!data[key].isAccess || (data[key].accessConditions && !data[key].accessConditions.access))) {
              continue
            }
            if (this.onlyControl && !data[key].isControlAccess) {
              continue
            }
            if (this.showMarkers && data[key]?.userTariff in this.tariffs) {
              let tariff = null

              tariff = this.tariffs[data[key]?.userTariff]
              tariff.date = data[key]?.userTariffFinishedAt
              data[key]['tariffOptions'].push(tariff)

              if (data[key]?.userHasMentor) {
                tariff = this.tariffs['mentorship_edu']
                tariff.date = data[key]?.userMentorFinishedAt
                data[key]['tariffOptions'].push(tariff)
              }
            }
            if (data[key].authors) {
              data[key].authors = data[key].authors.map((item) => new Author(item))
            }

            this.items.push(data[key])
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async removeItem(id) {
      if (!id || !this.can_delete) {
        this.loading = false
        this.$root.$emit('close')
        return
      }
      this.loading = true
      await axios
        .delete('/api/v2/courses/' + id)
        .then((res) => {
          this.$toastr.success(this.$t('general.deleted'))
        })
        .catch((e) => {})
      this.loading = false
      this.getListTraining()
      this.$root.$emit('close')
    },
  },
  components: {
    TrainingCard,
  },
}
</script>

<style lang="scss" scoped>
.swiper {
  overflow: visible;
  width: auto;
  margin: 0 -0.375rem;
  @include media-breakpoint-up(md) {
    margin: 0 -0.625rem;
  }
  @include media-breakpoint-up(xl) {
    margin: 0 -0.75rem;
  }
  &-wrapper {
    height: auto;
  }
  &-slide {
    height: auto;
    display: flex;
    flex: none;
  }
}
.list-training {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -0.75rem;
    @include media-breakpoint-up(md) {
      margin: 0 -0.625rem -1.25rem;
    }
    @include media-breakpoint-up(xl) {
      margin: 0 -0.75rem -1.5rem;
    }
  }
  &__item {
    padding: 0 0 0.75rem;
    @include media-breakpoint-up(md) {
      padding: 0 0.625rem 1.25rem;
      width: 50%;
    }
    @include media-breakpoint-up(xl) {
      padding: 0 0.75rem 1.5rem;
    }
    .swiper & {
      width: 15.75rem;
      padding: 0 0.375rem;
      @include media-breakpoint-up(md) {
        padding: 0 0.625rem;
        width: 20.75rem;
      }
      @include media-breakpoint-up(xl) {
        padding: 0 0.75rem;
        width: 23rem;
      }
    }
  }
}
</style>
