var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      attrs: {
        centered: "",
        closeOnBackdrop: true,
        title: _vm.$t("general.remove_data", { item: _vm.itemName }).toString(),
        show: _vm.show,
      },
      on: { "update:show": _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.confirmationValue
        ? _c("CInput", {
            attrs: {
              label: _vm.confirmText + _vm.confirmationValue,
              placeholder: _vm.confirmText + _vm.confirmationValue,
              type: "text",
            },
            model: {
              value: _vm.confirmValue,
              callback: function ($$v) {
                _vm.confirmValue = $$v
              },
              expression: "confirmValue",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "RemoveConfirmModal__btnBlock" },
        [
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "shadow-none RemoveConfirmModal__btn",
              attrs: {
                color: "success",
                loading: _vm.loading,
                disabled: _vm.loading || _vm.disabledForm,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("success", _vm.id)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("general.delete")) + " ")]
          ),
          _c(
            "CButton",
            {
              staticClass: "shadow-none RemoveConfirmModal__btn",
              attrs: { color: "primary", disabled: _vm.loading },
              on: { click: _vm.close },
            },
            [_vm._v(" " + _vm._s(_vm.$t("general.cancel")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }