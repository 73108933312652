<template>
  <div class="training-card">
    <RemoveConfirmModal
      v-if="can_delete"
      :id="String(training.id)"
      :itemName="training.slug || training.id"
      :confirmationValue="training.slug || training.id"
      :show="showRemoveConfirmModal"
      @close="showRemoveConfirmModal = false"
      @success="$emit('remove', $event)"
    />
    <div class="training-card__wrapper">
      <div
        class="training-card__controls"
        v-if="can_delete && !showBlackFriday"
      >
        <CButton
          v-if="can_delete"
          color="danger"
          @click="showRemoveConfirmModal = true"
        >
          <CIcon name="cil-x" />
        </CButton>
      </div>
      <router-link
        :to="{ name: to, params: { id: training.id, slug: training.slug || training.id } }"
        class="training-card__link"
      >
        <div
          class="training-card__markers"
          v-if="showMarkers && training.tariffOptions.length"
        >
          <div
            v-for="tariff in training.tariffOptions"
            :key="tariff.name.en"
            class="training-card__marker-item"
            :style="{
              backgroundColor: tariff.color || markerDefaultColor,
            }"
          >
            <span
              class="training-card__marker-type"
              v-if="tariff.name"
              >{{ tariff.name[locale] }}</span
            >
            <span
              class="training-card__marker-date"
              v-if="tariff.date"
              >{{ $t('lk.training.courseMarkerLabel') }} {{ formatDate(tariff.date) }}</span
            >
          </div>
        </div>

        <img
          :src="`${baseUrl}${training.course_cover_photo}`"
          :alt="training.course_title"
          v-if="training.course_cover_photo"
        />
      </router-link>
      <div class="training-card__content">
        <div class="training-card__title-wrapper">
          <h4
            class="training-card__title"
            v-if="training.course_title"
          >
            {{ training.course_title }}
          </h4>
          <!-- TTT-898
          <button
            type="button"
            class="training-card__arrow-btn"
            :class="{ _active: showSubInfo }"
            @click="showSubInfo = !showSubInfo"
            v-if="isSubInfo && showCurator"
          > ❭ </button>
          -->
        </div>
        <template v-if="training?.authors?.length">
          <small-author
            class="training-card__author"
            :author="training.authors[0]"
          ></small-author>
        </template>
        <template v-if="isSubInfo && showCurator">
          <div
            class="training-card__sub-info"
            v-show-slide:600="showSubInfo"
          >
            <div class="training-card__sub-info-content">
              <template v-if="curator && !isGuest">
                <small-author
                  class="training-card__author"
                  :author="curator"
                  :isCurator="true"
                ></small-author>
              </template>
              <CButton
                v-if="showLinkChat"
                role="button"
                color="primary"
                target="_blank"
                class="training-card__chat"
                :variant="training.userIsEnterTgChannel ? 'outline' : ''"
                @click="goGroupChat"
              >
                <span>
                  {{ training.userIsEnterTgChannel ? 'Вы вступили в чат' : 'Вступить в чат' }}
                </span>
                <CIcon name="cibTelegramPlane" />
              </CButton>
            </div>
          </div>
        </template>
      </div>

      <!-- <div
        v-if="showBlackFriday"
        class="training-card__discount"
      >
        <div class="training-card__discount__num">-20%</div>
        <div class="training-card__discount__text">Black Friday</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SmallAuthor from '@/components/training/SmallAuthor'
import moment from 'moment'
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal.vue'
import Author from '@/assets/js/Author.class'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TrainigCard',
  props: {
    to: {
      type: String,
      default: '',
    },
    toAuthor: {
      type: String,
      default: '',
    },
    training: {
      type: Object,
      required: true,
    },
    showMarkers: {
      type: Boolean,
      default: false,
    },
    can_delete: {
      type: Boolean,
      default: false,
    },
    showCurator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseUrl: axios.defaults.baseURL,
      markerDefaultColor: '#3686F6',
      showRemoveConfirmModal: false,
      showSubInfo: true,
    }
  },
  mounted() {
    this.$root.$on('close', () => {
      this.showRemoveConfirmModal = false
    })
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    ...mapState({
      userData: (state) => state.user.userData,
    }),
    locale() {
      return this.$root.$i18n.locale
    },
    curator() {
      return this.training?.userCurator ? new Author(this.training.userCurator) : null
    },
    showLinkChat() {
      return !!(this.training?.tg_group_url && this.training?.userIsEnterTgChannel !== undefined)
        && (!this.training?.accessConditions || this.training?.accessConditions.access)
    },
    isSubInfo() {
      return !!(this.showLinkChat || this.training?.userCurator)
    },
    blackFriday() {
      return this.userData.promotions.find((el) => el.name === 'blackfriday2023')
    },
    showBlackFriday() {
      return !this.showMarkers && !this.training.tariffOptions.length && this.blackFriday
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
    goGroupChat() {
      window.open(this.training.tg_group_url, '_blank')
    },
  },
  components: {
    SmallAuthor,
    RemoveConfirmModal,
  },
}
</script>

<style lang="scss" scoped>
.training-card {
  display: flex;
  flex: none;
  width: 100%;
  &__wrapper {
    position: relative;
    display: flex;
    flex: none;
    width: 100%;
    overflow: hidden;
    border-radius: 0.2rem;
  }
  &__content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    pointer-events: none;
    z-index: 1;
    padding: 2.5rem 1.5rem 1rem;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.01) 3.14%,
      rgba(0, 0, 0, 0.03) 6.29%,
      rgba(0, 0, 0, 0.06) 9.43%,
      rgba(0, 0, 0, 0.11) 12.57%,
      rgba(0, 0, 0, 0.17) 15.71%,
      rgba(0, 0, 0, 0.25) 18.86%,
      rgba(0, 0, 0, 0.33) 22%,
      rgba(0, 0, 0, 0.41) 25.14%,
      rgba(0, 0, 0, 0.49) 28.29%,
      rgba(0, 0, 0, 0.57) 31.43%,
      rgba(0, 0, 0, 0.63) 34.57%,
      rgba(0, 0, 0, 0.68) 37.71%,
      rgba(0, 0, 0, 0.71) 40.86%,
      rgba(0, 0, 0, 0.73) 44%,
      rgba(0, 0, 0, 0.74) 47.14%
    );
    @include media-breakpoint-up(xl) {
      padding: 5.375rem 1.5rem 1.5rem;
    }
  }
  ._is-carousel &__content {
    padding: 2.5rem 1rem 1rem;
    @include media-breakpoint-up(md) {
      padding: 2.5rem 1.5rem 1rem;
    }
    @include media-breakpoint-up(xl) {
      padding: 2.5rem 1.5rem 1rem;
    }
  }
  &__title-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 0 0.3125rem;
    @include media-breakpoint-up(xl) {
      gap: 0 0.5rem;
    }
  }
  &__title {
    font: 600 1.0625rem / 140% $font-family-montserrat;
    color: #ffffff;
    margin: 0;
    @include media-breakpoint-up(xl) {
      font-size: 1.625rem;
    }
  }
  ._is-carousel &__title {
    font-size: 0.9375rem;
    @include media-breakpoint-up(md) {
      font-size: 1.0625rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.1875rem;
    }
  }
  &__arrow-btn {
    display: block;
    width: 1.4375rem;
    height: 1.4375rem;
    cursor: pointer;
    top: 0.0625rem;
    position: relative;
    flex: none;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    transform: rotate(-90deg);
    font-weight: 700;
    transition: transform 0.5s;
    font-size: 0.625rem;
    color: #ffffff;
    line-height: 1.4375rem;
    pointer-events: auto;
    @include media-breakpoint-up(xl) {
      width: 2.25rem;
      height: 2.25rem;
      line-height: 2.25rem;
      font-size: 1rem;
      top: 0.125rem;
    }
    &._active {
      transform: rotate(90deg);
    }
    ._is-carousel & {
      width: 1.3125rem;
      height: 1.3125rem;
      line-height: 1.3125rem;
      @include media-breakpoint-up(md) {
        width: 1.4375rem;
        height: 1.4375rem;
        line-height: 1.4375rem;
      }
      @include media-breakpoint-up(xl) {
        width: 1.625rem;
        height: 1.625rem;
        line-height: 1.625rem;
        font-size: 0.75rem;
      }
    }
  }
  &__link {
    flex: none;
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    background: #ebedef;
    padding-top: 87.9%;
    @include media-breakpoint-up(md) {
      padding-top: 112.6%;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 106%;
    }
    &:hover {
      text-decoration: none;
    }
    .c-dark-theme & {
      background: #3c4b64;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      transition: transform 0.5s ease;
    }
    &:hover {
      img {
        transform: scale(1.03);
      }
    }
  }
  ._is-carousel &__link {
    padding-top: 102.5%;
    @include media-breakpoint-up(md) {
      padding-top: 123.5%;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 112%;
    }
  }
  &__title + &__author {
    margin-top: 0.5rem;
    @include media-breakpoint-up(xl) {
      margin-top: 0.625rem;
    }
  }
  ._is-carousel &__title + &__author {
    @include media-breakpoint-up(xl) {
      margin-top: 0.5rem;
    }
  }
  &__markers {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.375rem;
    width: 100%;
    z-index: 3;
    @include media-breakpoint-up(md) {
      padding: 1rem;
    }
    @include media-breakpoint-up(xl) {
      padding: 1rem 1.5rem;
    }
  }
  &__marker-item {
    color: #000000;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 0.25rem;
    padding: 0.375rem;
    @include media-breakpoint-up(md) {
      padding: 0.5rem 0.5625rem;
    }
    @include media-breakpoint-up(xl) {
      gap: 0 0.4375rem;
    }
  }
  &__marker-type {
    font-weight: 400;
    line-height: 1;
    font-size: 0.9375rem;
    text-transform: uppercase;
    display: block;
    @include media-breakpoint-up(xl) {
      font-size: 1.0625rem;
    }
  }
  &__marker-date {
    color: #2f353a;
    font-weight: 400;
    line-height: 1;
    font-size: 0.625rem;
    display: block;
  }
  &__controls {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
  }
  &__sub-info {
    &-content {
      padding-top: 0.5rem;
      display: flex;
      align-items: flex-end;
      gap: 0 1rem;
      flex-wrap: wrap;
      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
      }
      ._is-carousel & {
        @include media-breakpoint-up(lg) {
          flex-wrap: wrap;
        }
        @include media-breakpoint-up(xl) {
          flex-wrap: nowrap;
        }
      }
    }
  }
  &__chat {
    pointer-events: auto;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4rem;
    padding: 0.5rem 0.75rem;
    display: flex;
    column-gap: 0.38rem;

    ._is-carousel & {
      font-size: 0.8125rem;
      @include media-breakpoint-up(md) {
        font-size: 0.875rem;
      }
      @include media-breakpoint-up(lg) {
        margin: 0.375rem 0 0 3rem;
      }
      @include media-breakpoint-up(xl) {
        margin: 0.125rem 0;
      }
    }
  }

  &__discount {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%) rotate(45deg);
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    transform-origin: center top;
    padding-top: 25px;
    background: $danger;
    z-index: 1;
    color: white;
    pointer-events: none;

    &__num {
      padding-bottom: 5px;
      font-size: 20px;
    }

    &__text {
      font-size: 16px;
      padding: 5px 40px;
      background: rgba(black, 0.5);
    }
  }
}
</style>
